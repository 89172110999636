import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VBreadcrumbs,{attrs:{"large":"","items":[
    // {
    //   text: 'monplanning',
    //   disabled: false,
    //   href: getLink('/'),
    // },
    {
      text: 'Blog',
      disabled: false,
      to: _vm.getLink('blog'),
    },
    {
      text: _vm.blog.title,
      disabled: true,
      to: _vm.getLink('blog/' + _vm.blog.slugUrl),
    } ]},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c(VIcon,[_vm._v("mdi-chevron-right")])]},proxy:true}])}),_c(VContainer,[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"md":"8"}},[_c('h1',{domProps:{"innerHTML":_vm._s(_vm.blog.title)}}),_c('p',{staticClass:"grey--text text--darken-2"},[_vm._v(_vm._s(_vm.getDateDisplay(_vm.blog.date))+" - "+_vm._s(_vm.blog.durationInMinute)+" minutes de lecture")])])],1),_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"md":"8"}},[_c(VImg,{staticClass:"rounded-lg",attrs:{"src":_vm.getImageUrl(_vm.blog.imageUrl),"contain":""}})],1)],1),_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"md":"8"}},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.blog.htmlContent)}})])],1)],1),_c(VContainer,[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{staticClass:"text-center",attrs:{"md":"8"}},[_c(VBtn,{attrs:{"href":_vm.getPublicLink('home'),"target":"_blank","color":"primary"}},[_vm._v("Découvrir monplanning")])],1)],1)],1),(_vm.availableBlogs.length > 0)?_c(VContainer,[_c(VRow,{attrs:{"justify":"center","no-gutters":""}},[_c(VCol,{attrs:{"cols":"12","md":"10","sm":"4"}},[_c('span',{staticClass:"ml-2 text-h6 font-weight-medium"},[_vm._v("Si cet article vous a plu ..")]),_c(VSlideGroup,{attrs:{"show-arrows":""}},_vm._l((_vm.availableBlogs),function(b){return _c(VSlideItem,{key:b.slugUrl,scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c(VCard,{staticClass:"ma-2",attrs:{"width":_vm.$helpers.display.isMobile ? 150 : 250,"elevation":"1"}},[_c(VContainer,[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_c(VImg,{staticClass:"rounded-lg",attrs:{"src":_vm.getImageUrl(b.imageUrl),"contain":""}})],1)],1),_c(VRow,{staticClass:"mt-2",attrs:{"no-gutters":""}},[_c(VCol,[_c('router-link',{attrs:{"to":_vm.getLink(b.slugUrl)}},[_vm._v(_vm._s(b.title))])],1)],1)],1)],1)]}}],null,true)})}),1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }