<template>
  <div>
    <!-- <v-btn :to="getLink('blog')" plain><v-icon left>mdi-chevron-left</v-icon> Retour</v-btn> -->
    <v-breadcrumbs large :items="[
      // {
      //   text: 'monplanning',
      //   disabled: false,
      //   href: getLink('/'),
      // },
      {
        text: 'Blog',
        disabled: false,
        to: getLink('blog'),
      },
      {
        text: blog.title,
        disabled: true,
        to: getLink('blog/' + blog.slugUrl),
      },
    ]">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>

    <v-container>
      <v-row justify="center">
        <v-col md="8">
          <h1 v-html="blog.title"></h1>
          <p class="grey--text text--darken-2">{{ getDateDisplay(blog.date) }} - {{ blog.durationInMinute }} minutes de
            lecture</p>
        </v-col>

      </v-row>
      <v-row justify="center">
        <v-col md="8">
          <v-img :src="getImageUrl(blog.imageUrl)" class="rounded-lg" contain />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col md="8">
          <p v-html="blog.htmlContent"></p>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row justify="center">
        <v-col md="8" class="text-center">
          <v-btn :href="getPublicLink('home')" target="_blank" color="primary">Découvrir monplanning</v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="availableBlogs.length > 0">
      <v-row justify="center" no-gutters>
        <v-col cols="12" md="10" sm="4">
          <span class="ml-2 text-h6 font-weight-medium">Si cet article vous a plu ..</span>
          <v-slide-group show-arrows>
            <v-slide-item v-for="b in availableBlogs" :key="b.slugUrl" v-slot="{}">
              <v-card class="ma-2" :width="$helpers.display.isMobile ? 150 : 250" elevation="1">
                <v-container>
                  <v-row no-gutters>
                    <v-col>
                      <v-img :src="getImageUrl(b.imageUrl)" class="rounded-lg" contain />
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="mt-2">
                    <v-col>
                      <router-link :to="getLink(b.slugUrl)">{{ b.title }}</router-link>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import datehelper from '@/helpers/datehelper'

export default {
  name: "BlogItem",
  components: {},
  data() {
    return {
      blog: {},
      blogs: [],
    };
  },
  watch: {
    $route() {
      this.retrieveBlog();
    },
  },
  computed: {
    availableBlogs() {
      return this.blogs.filter(b => b.slugUrl != this.blog.slugUrl);
    }
  },
  methods: {
    isValidHttpUrl(string) {
      let url;
      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }
      return url.protocol === "http:" || url.protocol === "https:";
    },
    getImageUrl(imageUrl) {
      return this.isValidHttpUrl(imageUrl) ? imageUrl : `/static/blog/${imageUrl}`;
    },
    getFullImageUrl(imageUrl) {
      const url = this.getImageUrl(imageUrl);

      return url.includes('/static/blog/') ? 'https://www.monplanning.be' + url : url;
    },
    getPublicLink(url) {
      return this.$helpers.router.getPublicLink(url);
    },
    getLink(url) {
      return `/${this.$i18n.locale}/${url}`;
    },
    getDateDisplay(date) {
      return datehelper.getFullDateDisplay(date);
    },
    retrieveBlog() {
      const slugUrl = this.$route.name.replace('blog|', '');

      this.loading = true;

      this.$services.blog
        .get(slugUrl)
        .then((response) => {
          this.blog = response.data;
          this.loading = false;
        })
        .catch((error) => {
          this.$helpers.snackbar.handleError(error);
        });
    },
    retrieveBlogs() {
      this.loading = true;
      this.$services.blog
        .getAll()
        .then((response) => {
          this.blogs = response.data;
          this.loading = false;
        })
        .catch((error) => {
          this.$helpers.snackbar.handleError(error);
        });
    },
    retrieveAll() {
      this.retrieveBlog();
      this.retrieveBlogs();
    },
  },
  mounted() {
    this.retrieveAll();
  },
  metaInfo() {
    const blog = this.blog;
    
    return {
      // titleTemplate: blog.metaTitle,
      title: blog.metaTitle,
      meta: [
        {
          name: 'keywords',
          content: blog.metaKeywords,
        },
        {
          name: "description",
          content: blog.metaDescription,
        },
        // {
        //   property: 'og:title',
        //   content: blog.metaTitle
        // },
        // {
        //   property: 'og:url',
        //   content: `https://www.monplanning.be/fr/${blog.slugUrl}`,
        // },
        // {
        //   property: 'og:type',
        //   content: 'article',
        // },
        // {
        //   property: 'og:description',
        //   content: blog.metaDescription,
        // },
        // {
        //   property: 'og:image',
        //   content: this.getFullImageUrl(blog.imageUrl),
        // },
      ],
    };
  },
};
</script>